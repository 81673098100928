<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
  <div id="issue">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />详情
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div class="issue-detail">
      <div class="detail">
        <div class="detail-title">{{ notification.notification.title }}</div>
        <p
          class="content"
          v-if="notification.notification.notification_type == '文件'"
        >
          <a
            target="_blank"
            :href="notification.notification.file_url"
            style="color: #6c94f6"
          >
            下载</a
          >
          <!-- <embed :src="notification.file_url" style="width: 100%; height: 100%"/> -->
          <!-- <iframe frameborder="0"
                :src="'https://view.officeapps.live.com/op/view.aspx?src=' + notification.file_url"
                width='100%'>
            </iframe> -->
        </p>
        <p
          class="content"
          v-else
          v-html="notification.notification.content"
        ></p>
        <van-row>
          <van-col span="12" class="date" style="text-align:left;">{{ notification.notification.author }}</van-col>
          <van-col span="12" class="date">{{ notification.notification.created_at }}</van-col>
        </van-row>
        <!-- <div class="date">{{ notification.notification.created_at }}</div> -->
      </div>
      <div class="unread-content"  v-show="notification.notification.notification_type=='1'||notification.notification.notification_type=='2'">
        <div class="issue-sub-title">谁未读？</div>
        <van-row
          class="unread-item"
          v-for="(item, index) in notification.unread_users"
          :key="index"
        >
          <van-col span="12" class="content-text"
            >{{ item.userable_name
            }}<svg-icon
              v-if="item.userable_type == 'Guide'"
              :data_iconName="'guider'"
              :className="'guider_icon'" />
            <svg-icon
              v-if="item.userable_type == 'TravelCompany'"
              :data_iconName="'agency'"
              :className="'agency_icon'"
          />
          <svg-icon
              v-if="item.userable_type == 'Tourist'"
              :data_iconName="'visitor'"
              :className="'visitor_icon'"
              
          /></van-col>
          <van-col span="12" class="content-text">{{ item.userable_gender?item.userable_gender:item.username }}</van-col>
          <van-col span="24" class="content-text"
            >电话：{{ item.mobile }}</van-col
          >
          <!-- <van-col span="12" class="content-text">{{ item.mobile }}</van-col> -->
        </van-row>
        <van-empty v-if="notification.unread_users.length==0" :image="readPng" description="都读了" />
      </div>
    </div>
  </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
export default {
  name: "issue-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      notification: {
        notification: {
          title: "",
          notification_type: "3",
        },
        unread_users:[]
      },
      active: 1,
      readPng:require('../../assets/images/read.png')
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async get_notification(id) {
      const res = await this.$apis.get_notification(id);
      // const read = await this.$apis.read_notification(id);
      this.notification = res.data;
      this.loading = false;
      this.refreshing = false;
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_notification(id);
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#issue {
  height: 100vh;
  /* background: #f3f3f3; */
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-detail {
  padding: 0.2rem 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.detail {
  /* min-height: 80vh; */
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.4rem 0.2rem;
}
.detail-title {
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content {
  font-size: 14px;
  color: #666666;
  text-align: justify;
  margin-top: 0.5rem;
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.unread-content {
  /* height: 80vh;
  overflow-y: scroll; */

  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgb(182 179 179 / 54%);
  border-radius: 16px;
  padding: 0rem 0.2rem;
  /* padding-bottom: 4rem; */
   padding-bottom: .2rem;
}
.issue-sub-title {
  color: #333;
  font-size: 0.3rem;
  font-weight: bold;
  height: 1rem;
  line-height: 1rem;
}
.content-text {
  font-size: 14px;
  color: #666666;
  text-align: justify;
}
.unread-item {
  /* margin-bottom: 10px; */
  /* background: #fff; */
  padding: 0.1rem 0.3rem;
}
.unread-item:nth-child(even) {
  background: #f4f3f3 !important;
}
</style>
